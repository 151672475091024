import * as amplitude from '@amplitude/analytics-browser';

export function initAnalyticsBrowser(apiKey: string) {
  amplitude.init(apiKey, undefined, {
    serverZone: 'EU',
    autocapture: true,
  });
}

type EventName =
  | "future-feature"
  | "delete-booking"

export function trackAnalyticEvent(eventName: EventName, eventData?: Record<string, any>, eventOptions?: amplitude.Types.EventOptions) {
  amplitude.track(eventName, eventData);
}

export function trackAnalyticRoute(pathname: string, params: any) {
  amplitude.track("page_view", {
    pathname,
    params,
  });
}

export function registerUserAnalytics(userId: string) {
  amplitude.setUserId(userId)

  const event = new amplitude.Identify();
  event.set("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);
  amplitude.identify(event);
}
